import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  unityAssets: HomeUnityAssetListing[] = [
    {
      title: 'TongueTwister',
      image: '../../assets/images/tt-logo-small.png',
      description: 'Localization Management Tools for Unity',
      buttons: [
        {
          title: 'DOCS',
          url: 'https://www.austephner.com/tongue-twister/index.html'
        },
        {
          title: 'ASSET STORE',
          url: 'https://assetstore.unity.com/packages/slug/182281'
        },
        {
          title: 'DISCORD',
          url: 'https://discord.gg/zpJJznH',
        }
      ]
    },
    {
      title: 'Dev Console',
      image: '../../assets/images/DevConsoleLogo.png',
      description: 'A simple in-game developer console with easy-to-implement commands and scripting.',
      buttons: [
        {
          title: 'GITHUB',
          url: 'https://github.com/austephner/DevConsole'
        }
      ]
    },
    {
      title: 'ProtoGUI',
      image: '../../assets/images/protogui-logo.png',
      description: 'Collection of useful prototyping IMGUI classes and functionality.',
      buttons: [
        {
          title: 'GITHUB',
          url: 'https://github.com/austephner/ProtoGUI'
        }
      ]
    },
    {
      title: 'Randomizable Fields',
      image: '../../assets/images/R-logo.png',
      description: 'Collection of attributes for randomizing fields in the Unity Editor',
      buttons: [
        {
          title: 'GITHUB',
          url: 'https://github.com/austephner/RandomizableFields'
        }
      ]
    },
    // TEMPORARILY COMMENTING OUT BECAUSE IT'S A WIP AND NOT READY
    // {
    //   title: 'Character Profile Management API (WIP)',
    //   image: '../../assets/images/cpmapi-logo-1.png',
    //   description: 'Simple and Lightweight Profile Randomization API for Unity, currently a work-in-progress',
    //   buttons: [
    //     {
    //       title: 'GITHUB',
    //       url: 'https://github.com/austephner/CharacterProfileManagementAPI'
    //     }
    //   ]
    // },
  ];

  ngOnInit() {}
  constructor() {}

}

export class HomeUnityAssetListing {
  title: string;
  image: string;
  description: string;
  // docsUrl: string;
  // assetUrl: string;
  // discordUrl: string;
  buttons: AssetListingButton[];
}

export class AssetListingButton {
  title: string;
  url: string;
}
<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon>support</mat-icon>
            SUPPORT
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p class="centered-text email-label"><span>{{supportContactUser}}</span>@<span>{{contactDomain}}</span><span>{{contactSuffix}}</span></p>
        <ul>
            <li>Unity Asset Support ~ 1 business day response time.</li>
            <li>Expect slower responses during holiday periods.</li>
            <li class="important-item">If possible, please include your Unity Asset Store invoice number.</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon>business</mat-icon>
            INQUIRIES
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="centered-text-container">
        <p class="centered-text">We are currently not taking any business inquiries at this time.</p>
    </mat-card-content>
</mat-card>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialsModule } from './angular-materials/angular-materials.module';
import { HomeComponent } from './views/home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { CenteredProgressSpinnerComponent } from './shared/centered-progress-spinner/centered-progress-spinner.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { FooterComponent } from './shared/footer/footer.component';
import { SpacerComponent } from './shared/spacer/spacer.component';
import { MatIconModule } from '@angular/material/icon';
import { ContactComponent } from './views/contact/contact.component';
import { SupportComponent } from './views/support/support.component';
import { AboutComponent } from './views/about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    NavigationComponent,
    CenteredProgressSpinnerComponent,
    PageNotFoundComponent,
    FooterComponent,
    SpacerComponent,
    ContactComponent,
    SupportComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialsModule,
    MatIconModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    StorageServiceModule,
  ],
  providers: [
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

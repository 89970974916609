<nav mat-tab-nav-bar color="primary">
    <a mat-tab-link
        *ngFor="let link of navLinks"
        [routerLink]="link.path"
        routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive">
        <mat-icon *ngIf="link.data.customIcon" [svgIcon]="link.data.icon" class="icon"></mat-icon>
        <mat-icon *ngIf="!link.data.customIcon" class="icon">{{link.data.icon}}</mat-icon>
        {{link.data.label}}
    </a>
</nav> 
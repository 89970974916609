<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon>build</mat-icon>
            UNITY ASSETS
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngFor="let unityAsset of unityAssets" class="unity-asset">
            <div class="header">
                <img [src]="unityAsset.image"/>
                <div class="details">
                    <p class="title">{{unityAsset.title}}</p>
                    <p class="description">{{unityAsset.description}}</p>
                </div>
            </div>
            <div class="buttons-container">
                <a mat-stroked-button *ngFor="let button of unityAsset.buttons" [href]="button.url" target="_blank">{{button.title}}</a>
            </div>
        </div>
    </mat-card-content>
</mat-card>
<app-contact></app-contact>
<app-about></app-about>
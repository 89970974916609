import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './views/about/about.component';
import { ContactComponent } from './views/contact/contact.component';
import { HomeComponent } from './views/home/home.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      label: 'Home',
      icon: 'house',
      customIcon: false,
      showInNavBar: true,
      animation: 'swipe'
    }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      label: 'Contact',
      icon: 'email',
      customIcon: false,
      showInNavBar: true,
      animation: 'swipe'
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      label: 'About',
      icon: 'info',
      customIcon: false,
      showInNavBar: true,
      animation: 'swipe'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      showInNavBar: false,
      animation:'swipe'
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  navLinks: any[];
  constructor(
    private router: Router,
    private routingModule: AppRoutingModule,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.navLinks = this.router.config.filter(route => route.path !== '' && route.data.showInNavBar === true);
    this.matIconRegistry.addSvgIcon(
      'tonguetwister',
      // 'assets/images/logo.png'
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/tt-logo-svg.SVG')
    );
   }

  ngOnInit(): void {
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  supportContactUser: string = 'support';
  inquiryContactUser: string = 'inquiries';
  contactDomain: string = 'austephner';
  contactSuffix: string = '.com';

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
  }

  getDeviceBasedCols() : number {
    return this.checkIsMobile() ? 1 : 2;
  }

  checkIsMobile() : boolean {
    return this.deviceService.isMobile();
  }
}

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon>info</mat-icon>
            ABOUT
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        Austephner LLC is an US based company formed on April 20th, 2020. The company focuses on game development assets and tools.
    </mat-card-content>
</mat-card>